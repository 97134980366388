import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import BlueLabel from "../images/logo-blue-small.svg"
import GradientImage from "../images/blue-gradient.png"

const StandardLabelBeantragen = () => {
  const [steps, setSteps] = useState(() => [true, ...new Array(6).fill(false)])

  useEffect(() => {
    if (process.env.GATSBY_VARIANT === "Europe") {
      navigate("/en/application-procedure")
    }
  }, [])

  function toggleCard(index) {
    setSteps(previousState => {
      return [...previousState].map((step, i) => (i === index ? !step : false))
    })
  }

  return (
    <React.Fragment>
      <PageTitle title={"Cyber Trust Austria Standard Label"} />
      <Layout>
        <div className="application-procedure-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>Cyber Trust Austria Standard Label</a>
              </li>
            </ul>
          </div>
          <div className="application-procedure">
            <h1>Cyber Trust Austria Standard Label</h1>
            <div className="application-procedure__wrapper">
              <div className="image">
                <img
                  src={BlueLabel}
                  className="mw-100"
                  alt="Cyber Trust Austria Standard Label"
                  title="Cyber Trust Austria Standard Label"
                />
              </div>
              <h3>
                Ablauf bei der Beantragung eines Cyber Trust Austria Labels
              </h3>
              <div className="page__flex">
                <div className="application-procedure__left">
                  <div className="application-procedure__left--block">
                    <div
                      className={`application-procedure__left--box${
                        steps[0] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(0)}
                      >
                        Online-<strong>Beantragung</strong>
                      </div>
                      <div className="body">
                        Die{" "}
                        <a
                          className="js-popup"
                          onClick={() =>
                            navigate("/label-beantragen?label=BLUE")
                          }
                        >
                          Beantragung
                        </a>{" "}
                        erfolgt online auf dieser Webseite.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[1] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(1)}
                      >
                        <strong>Beantwortung</strong> des Online Fragebogens
                      </div>
                      <div className="body">
                        Sie erhalten einen Link zur Beantwortung der 14 Fragen
                        des KSV1870 B-Ratings und machen dort ihre Angaben.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[2] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(2)}
                      >
                        <strong>Durchführung</strong> des automatisierten Web
                        Scorings (der angegebenen qualifizierten Domäne)
                      </div>
                      <div className="body">
                        Es erfolgt ein eine automatisierte Sicherheitsbewertung
                        der von Ihnen angegebenen Domäne. Diese erfolgt rein
                        passiv und ist völlig ungefährlich.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[3] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(3)}
                      >
                        <strong>Validierung</strong> der Antworten & Errechnung
                        des vorläufigen Cyber Risk Ratings
                      </div>
                      <div className="body">
                        Die von ihnen eingegebenen Antworten werden vom KSV1870
                        validiert und Ihre Angaben auf Vollständigkeit und
                        Plausibilität überprüft.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[4] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(4)}
                      >
                        <strong>Möglichkeit zur Klärung</strong> offener Punkte
                        & Richtigstellung
                      </div>
                      <div className="body">
                        In einer Klärungsphase können allfällige Unklarheiten
                        oder unvollständige Antworten geklärt werden.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[5] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(5)}
                      >
                        <strong>Erstellung</strong> des finalen Cyber Risk
                        Ratings
                      </div>
                      <div className="body">
                        Auf Basis der validierten Antworten errechnet sich das
                        finale Rating. Sie erhalten eine Kopie der Antworten und
                        allfälliger Rückfragen und Kommentare. Ihre Angaben
                        werden weder beim KSV1870 noch bei Cyber Trust Services
                        gespeichert.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box${
                        steps[6] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(6)}
                      >
                        <strong>Ausstellung</strong> des Labels & Eintrag in die
                        Cyber Trust Label Datenbank
                      </div>
                      <div className="body">
                        Wenn die Qualifikationskriterien für das Label erfüllt
                        sind (Vorliegen eines B-Ratings von 190 oder besser),
                        dann erhalten Sie eine Label-Bestätigung mit dem
                        Nutzungsrecht des Labels für ein Jahr und werden in die
                        Label-Datenbank eingetragen.
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="application-procedure__right">
              <div className="sticky">
                <div className="application-procedure__block application-procedure__block--main">
                  <div className="image">
                    <img src={BlueLabel} className="mw-100" />
                  </div>
                  <div className="name">
                    Cyber Trust Austria Labels
                  </div>
                  <div className="application-procedure__button">
                    <a onClick={() => navigate("/label-beantragen?label=BLUE")} className="page__btn page__btn--blue js-popup" data-bg="#E2EAFF" data-btnbg="#1854A2" data-child="2" data-rel="rel2">
                      Beantragung
                    </a>
                    <Link to="/list-question-b" className="page__btn page__btn--blue-border">
                      Anforderungsliste
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
                <div className="application-procedure__buttons_container">
                  <a
                    onClick={() => navigate("/label-beantragen?label=BLUE")}
                    className="page__btn page__btn--blue js-popup"
                    data-bg="#E2EAFF"
                    data-btnbg="#1854A2"
                    data-child="2"
                    data-rel="rel2"
                  >
                    Jetzt Label beantragen
                  </a>
                  <Link
                    to="/list-question-b"
                    className="page__btn page__btn--blue-border"
                  >
                    Anforderungsliste
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default StandardLabelBeantragen
